<template>
	<header
		v-if="content && content.length"
		class="c-front-page-hero"
		:style="{
			'--content-height': contentHeight ? `${contentHeight}px` : null,
		}"
	>
		<!-- Main content -->
		<div
			class="
				flex flex-col
				justify-center
				col-start-1 col-span-5
				self-stretch
			"
			:class="['pt-md pb-sm', '>=1024:pt-sm >=1024:pb-xl']"
		>
			<nav
				v-if="content.length > 1"
				aria-label="intro-område-navigering"
				class="flex items-center gap-x-8 mb-sm"
			>
				<input
					v-for="(item, index) in content"
					:key="index"
					type="radio"
					name="intro-nav"
					:aria-label="`Gå til intro-område ${index + 1}`"
					:aria-current="introIndex === index ? 'true' : null"
					class="
						appearance-none
						border border-primary-button
						w-12
						h-12
						rounded-full
						transition-colors
						duration-300
					"
					:class="{
						'bg-primary cursor-pointer': introIndex !== index,
						'bg-primary-button': introIndex === index,
					}"
					@change="$event.target.checked && (introIndex = index)"
				/>
			</nav>

			<TransitionGroup
				tag="div"
				name="t-front-page-hero__content-transition"
				class="
					c-front-page-hero__content-transition
					relative
					flex flex-col
					justify-center
					items-start
					my-auto
				"
				:class="{
					'c-front-page-hero__content-transition--back':
						introIndex < previousIntroIndex,
				}"
				@before-leave="updateContentHeight"
				@after-enter="contentHeight = 0"
			>
				<template v-for="(item, index) in content">
					<div
						v-show="introIndex === index"
						:key="index"
						ref="content"
						class="c-front-page-hero__content"
					>
						<BaseH1
							class="text-primary-button"
							v-text="item.title"
						/>

						<p
							v-if="item.teaser"
							class="
								whitespace-pre-wrap
								mt-xs
								transform
								-translate-y-4
							"
							v-text="item.teaser"
						></p>

						<BaseButton
							v-if="item.link && item.link[0]"
							tag="NuxtLinkExt"
							class="inline-flex mt-xs"
							:to="item.link[0].url"
							:target="item.link[0].target"
							v-text="item.link[0].name"
						/>
					</div>
				</template>
			</TransitionGroup>
		</div>

		<!-- Media -->
		<Transition name="t-front-page-hero__media" mode="out-in">
			<div
				v-if="heroMedia"
				:key="introIndex"
				:class="[
					'c-front-page-hero__media',
					'relative bg-primary-medium rounded-l-16',
					'col-start-6 col-span-7 self-stretch',
				]"
			>
				<!-- Image -->
				<UmbracoImageExt
					v-if="
						heroMedia.fileType === 'Image' &&
						(heroMedia.cropUrl || heroMedia.url)
					"
					:alt="heroMedia.altText"
					:widths="[320, 480, 720, 834, 480 * 2, 720 * 2, 834 * 2]"
					:source-url="heroMedia.cropUrl || heroMedia.url"
					:source-width="heroMedia.width"
					:source-height="heroMedia.height"
					:aspect-ratio="834 / 501"
					:class="[
						'c-front-page-hero__image bg-primary-medium relative rounded-l-16 overflow-hidden',
					]"
					image-class-names="object-cover"
				/>

				<!-- Video -->
				<div
					v-else-if="
						heroMedia.fileType === 'Video' && heroMedia.cropUrl
					"
					class="
						c-front-page-hero__video
						relative
						rounded-l-16
						h-full
					"
				>
					<video
						id="hero-video"
						ref="heroVideo"
						autoplay
						muted
						playsInline
						loop
						preload="none"
						crossOrigin="anonymous"
						class="
							absolute
							left-0
							top-0
							w-full
							h-full
							rounded-l-16
							object-cover
						"
						aria-label="stemningsvideo"
						@canplay="pauseIfItShouldntPlay"
					>
						<source :src="heroMedia.cropUrl" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
			</div>

			<!-- Empty -->
			<div v-else key="nothing"></div>
		</Transition>

		<!-- Video controls if video -->
		<Transition name="t-front-page-hero__media" mode="out-in">
			<div
				v-if="heroMedia && heroMedia.fileType === 'Video'"
				class="absolute right-0 bottom-0"
			>
				<BaseButton
					v-if="videoIsPlaying"
					class="
						c-front-page-hero__toggle
						absolute
						bottom-16
						right-layout-margin
						z-10
					"
					aria-label="Sæt videoen på pause"
					aria-controls="hero-video"
					@click="toggleVideo()"
				>
					<template #icon>
						<SvgPauseIcon class="w-24 h-24" />
					</template>
				</BaseButton>
				<BaseButton
					v-else
					class="
						c-front-page-hero__toggle
						absolute
						bottom-16
						right-layout-margin
						z-10
					"
					aria-label="Afspil videoen"
					aria-controls="hero-video"
					@click="toggleVideo()"
				>
					<template #icon>
						<div class="w-24 h-24 flex items-center justify-center">
							<SvgPlayIcon class="w-16 ml-2" />
						</div>
					</template>
				</BaseButton>
			</div>
		</Transition>
	</header>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt.vue';
import SvgPlayIcon from '~/assets/svgs/play-icon.svg?inline';
import SvgPauseIcon from '~/assets/svgs/pause-icon.svg?inline';

export default {
	name: 'FrontPageHero',
	components: {
		UmbracoImageExt,
		SvgPlayIcon,
		SvgPauseIcon,
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		content: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			introIndex: 0,
			previousIntroIndex: 0,
			contentHeight: 0,
			videoIsPlaying: true,
		};
	},
	computed: {
		heroMedia() {
			return this.content[this.introIndex]?.imageVideo;
		},
	},
	watch: {
		value(newVal) {
			this.introIndex = newVal;
		},
		introIndex(newVal, oldVal) {
			this.previousIntroIndex = oldVal;
			this.$emit('input', newVal);
		},
	},
	methods: {
		toggleVideo() {
			this.videoIsPlaying = !this.videoIsPlaying;
			if (this.videoIsPlaying) {
				this.$refs.heroVideo?.play();
			} else {
				this.$refs.heroVideo?.pause();
			}
		},
		pauseIfItShouldntPlay() {
			if (!this.videoIsPlaying) {
				this.$refs.heroVideo?.pause();
			}
		},

		updateContentHeight() {
			const height =
				this.$refs.content?.[this.previousIntroIndex]?.offsetHeight;
			this.contentHeight = 0;

			height &&
				this.$nextTick(() => {
					const height2 =
						this.$refs.content?.[this.introIndex]?.offsetHeight;
					if (height && height2 && height !== height2) {
						this.contentHeight = height;

						this.$nextTick(() => {
							this.contentHeight = height2;
						});
					}
				});
		},
	},
};
</script>

<style lang="postcss">
.c-front-page-hero {
	@apply relative grid grid-cols-12 gap-x-layout-gutter px-layout-margin bg-white;
	min-height: min(501px, 80vh - 100px);
	z-index: 21;
	overflow: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		height: min(50%, 202px);
		background: linear-gradient(
			0deg,
			var(--color-primary) 0%,
			var(--color-primaryTransparent) 100%
		);
	}
}
.c-front-page-hero__content {
	@apply flex flex-col justify-center items-start;
	transition: height 200ms ease-in-out;
	height: var(--content-height, auto);
}
.c-front-page-hero__media {
	height: min(501px, 80vh - 100px);
	min-height: 100%;
	z-index: -1;
}
.c-front-page-hero__image,
.c-front-page-hero__video {
	width: calc(
		100% + var(--theme-layout-margin, var(--theme-layout-margin--sm))
	);
	height: 100%;
}

/* Vue transition */
.t-front-page-hero__content-transition-enter-active,
.t-front-page-hero__content-transition-leave-active {
	@apply transition-all duration-300;
}
.t-front-page-hero__content-transition-enter-active {
	@apply delay-300;
}
.t-front-page-hero__content-transition-leave-active {
	@apply absolute w-full;
}
.t-front-page-hero__content-transition-enter,
.t-front-page-hero__content-transition-leave-to {
	@apply opacity-0;
}
.t-front-page-hero__content-transition-enter {
	@apply transform translate-x-16 ease-out;
}
.c-front-page-hero__content-transition--back
	.t-front-page-hero__content-transition-enter {
	@apply -translate-x-16;
}
.t-front-page-hero__content-transition-leave-to {
	@apply transform -translate-x-16 ease-in;
}
.c-front-page-hero__content-transition--back
	.t-front-page-hero__content-transition-leave-to {
	@apply translate-x-16;
}

.t-front-page-hero__media-enter-active,
.t-front-page-hero__media-leave-active {
	@apply transition-opacity duration-300;
}
.t-front-page-hero__media-enter,
.t-front-page-hero__media-leave-to {
	@apply opacity-0;
}
</style>
