var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.length)?_c('header',{staticClass:"c-front-page-hero-mobile",style:({
		'--content-height':
			typeof _vm.contentHeight === 'number' ? (_vm.contentHeight + "px") : null,
		'--content-height-2':
			typeof _vm.contentHeight2 === 'number'
				? (_vm.contentHeight2 + "px")
				: null,
	})},[_c('div',{staticClass:"\n\t\t\tflex flex-col\n\t\t\tjustify-center\n\t\t\tcol-start-1 col-span-5\n\t\t\tself-stretch\n\t\t"},[_c('TransitionGroup',{staticClass:"\n\t\t\t\tc-front-page-hero-mobile__content-transition\n\t\t\t\trelative\n\t\t\t\tflex flex-col\n\t\t\t\tjustify-center\n\t\t\t\titems-start\n\t\t\t",class:{
				'c-front-page-hero-mobile__content-transition--back':
					_vm.introIndex < _vm.previousIntroIndex,
			},attrs:{"tag":"div","name":"t-front-page-hero-mobile__content-transition"},on:{"before-leave":_vm.updateContentHeight,"after-enter":function($event){_vm.contentHeight = null}}},[_vm._l((_vm.content),function(item,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.introIndex === index),expression:"introIndex === index"}],key:index,ref:"content",refInFor:true,staticClass:"c-front-page-hero-mobile__content"},[_c('div',{staticClass:"\n\t\t\t\t\t\t\tc-front-page-hero-mobile__headline-wrapper\n\t\t\t\t\t\t\tpy-sm\n\t\t\t\t\t\t"},[_c('BaseH1',{staticClass:"text-primary-button",domProps:{"textContent":_vm._s(item.title)}})],1),_vm._v(" "),(item.imageVideo)?_c('div',{class:[
							'c-front-page-hero-mobile__media',
							'relative bg-primary-medium' ]},[(
								item.imageVideo.fileType === 'Image' &&
								item.imageVideo.cropUrl
							)?_c('UmbracoImageExt',{class:[
								'c-front-page-hero-mobile__image bg-primary-medium relative overflow-hidden' ],attrs:{"alt":item.imageVideo.altText,"widths":[
								320,
								480,
								720,
								834,
								1024,
								720 * 2,
								834 * 2,
								1024 * 2 ],"source-url":item.imageVideo.cropUrl ||
								item.imageVideo.url,"source-width":item.imageVideo.width,"source-height":item.imageVideo.height,"aspect-ratio":375 / 281,"image-class-names":"object-cover"}}):(
								item.imageVideo.fileType === 'Video' &&
								item.imageVideo.cropUrl
							)?_c('div',{staticClass:"\n\t\t\t\t\t\t\t\tc-front-page-hero-mobile__video\n\t\t\t\t\t\t\t\trelative\n\t\t\t\t\t\t\t\th-full\n\t\t\t\t\t\t\t"},[_c('video',{ref:"heroVideo",refInFor:true,staticClass:"\n\t\t\t\t\t\t\t\t\tabsolute\n\t\t\t\t\t\t\t\t\tleft-0\n\t\t\t\t\t\t\t\t\ttop-0\n\t\t\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\t\t\th-full\n\t\t\t\t\t\t\t\t\tobject-cover\n\t\t\t\t\t\t\t\t",attrs:{"id":"hero-video","autoplay":"","muted":"","playsInline":"","loop":"","preload":"none","crossOrigin":"anonymous","aria-label":"stemningsvideo"},domProps:{"muted":true},on:{"canplay":_vm.setCorrectPausePlay}},[_c('source',{attrs:{"src":item.imageVideo.cropUrl,"type":"video/mp4"}}),_vm._v("\n\t\t\t\t\t\t\t\tYour browser does not support the video tag.\n\t\t\t\t\t\t\t")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(
							item.imageVideo &&
							item.imageVideo.fileType === 'Video'
						)?_c('div',{staticClass:"absolute right-0 bottom-0"},[(_vm.videoIsPlaying)?_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\t\tc-front-page-hero-mobile__toggle\n\t\t\t\t\t\t\t\tabsolute\n\t\t\t\t\t\t\t\tbottom-16\n\t\t\t\t\t\t\t\tright-0\n\t\t\t\t\t\t\t\tz-10\n\t\t\t\t\t\t\t",attrs:{"aria-label":"Sæt videoen på pause","aria-controls":"hero-video"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgPauseIcon',{staticClass:"w-24 h-24"})]},proxy:true}],null,true)}):_c('BaseButton',{staticClass:"\n\t\t\t\t\t\t\t\tc-front-page-hero-mobile__toggle\n\t\t\t\t\t\t\t\tabsolute\n\t\t\t\t\t\t\t\tbottom-16\n\t\t\t\t\t\t\t\tright-0\n\t\t\t\t\t\t\t\tz-10\n\t\t\t\t\t\t\t",attrs:{"aria-label":"Afspil videoen","aria-controls":"hero-video"},on:{"click":function($event){return _vm.toggleVideo()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\tw-24\n\t\t\t\t\t\t\t\t\t\th-24\n\t\t\t\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\t\t\t"},[_c('SvgPlayIcon',{staticClass:"w-16 ml-2"})],1)]},proxy:true}],null,true)})],1):_vm._e()])]})],2),_vm._v(" "),_c('div',{staticClass:"c-front-page-hero-mobile__secondary-area"},[(_vm.content.length > 1)?_c('nav',{staticClass:"flex items-center gap-x-8 mb-sm z-10",attrs:{"aria-label":"intro-område-navigering"}},_vm._l((_vm.content),function(item,index){return _c('input',{key:index,staticClass:"\n\t\t\t\t\t\tappearance-none\n\t\t\t\t\t\tborder border-primary-button\n\t\t\t\t\t\tw-12\n\t\t\t\t\t\th-12\n\t\t\t\t\t\trounded-full\n\t\t\t\t\t\ttransition-colors\n\t\t\t\t\t\tduration-300\n\t\t\t\t\t",class:{
						'bg-primary cursor-pointer': _vm.introIndex !== index,
						'bg-primary-button': _vm.introIndex === index,
					},attrs:{"type":"radio","name":"intro-nav","aria-label":("Gå til intro-område " + (index + 1)),"aria-current":_vm.introIndex === index ? 'true' : null},on:{"change":function($event){$event.target.checked && (_vm.introIndex = index)}}})}),0):_vm._e(),_vm._v(" "),_c('TransitionGroup',{staticClass:"\n\t\t\t\t\tc-front-page-hero-mobile__content-transition\n\t\t\t\t\trelative\n\t\t\t\t\tflex flex-col\n\t\t\t\t\tjustify-center\n\t\t\t\t\titems-start\n\t\t\t\t",class:{
					'c-front-page-hero-mobile__content-transition--back':
						_vm.introIndex < _vm.previousIntroIndex,
				},attrs:{"tag":"div","name":"t-front-page-hero-mobile__content-transition"},on:{"after-enter":function($event){_vm.contentHeight2 = null}}},[_vm._l((_vm.content),function(item,index){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
							_vm.introIndex === index &&
							(item.teaser || (item.link && item.link[0]))
						),expression:"\n\t\t\t\t\t\t\tintroIndex === index &&\n\t\t\t\t\t\t\t(item.teaser || (item.link && item.link[0]))\n\t\t\t\t\t\t"}],key:index,ref:"content2",refInFor:true,staticClass:"\n\t\t\t\t\t\t\tc-front-page-hero-mobile__content\n\t\t\t\t\t\t\tc-front-page-hero-mobile__content--2\n\t\t\t\t\t\t"},[(item.teaser)?_c('p',{staticClass:"\n\t\t\t\t\t\t\t\twhitespace-pre-wrap\n\t\t\t\t\t\t\t\tmt-xs\n\t\t\t\t\t\t\t\ttransform\n\t\t\t\t\t\t\t\t-translate-y-4\n\t\t\t\t\t\t\t",domProps:{"textContent":_vm._s(item.teaser)}}):_vm._e(),_vm._v(" "),(item.link && item.link[0])?_c('BaseButton',{staticClass:"inline-flex mt-xs",attrs:{"tag":"NuxtLinkExt","to":item.link[0].url,"target":item.link[0].target},domProps:{"textContent":_vm._s(item.link[0].name)}}):_vm._e()],1)]})],2)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }