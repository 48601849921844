<template>
	<header
		v-if="content && content.length"
		class="c-front-page-hero-mobile"
		:style="{
			'--content-height':
				typeof contentHeight === 'number' ? `${contentHeight}px` : null,
			'--content-height-2':
				typeof contentHeight2 === 'number'
					? `${contentHeight2}px`
					: null,
		}"
	>
		<!-- Main content -->
		<div
			class="
				flex flex-col
				justify-center
				col-start-1 col-span-5
				self-stretch
			"
		>
			<TransitionGroup
				tag="div"
				name="t-front-page-hero-mobile__content-transition"
				class="
					c-front-page-hero-mobile__content-transition
					relative
					flex flex-col
					justify-center
					items-start
				"
				:class="{
					'c-front-page-hero-mobile__content-transition--back':
						introIndex < previousIntroIndex,
				}"
				@before-leave="updateContentHeight"
				@after-enter="contentHeight = null"
			>
				<template v-for="(item, index) in content">
					<div
						v-show="introIndex === index"
						:key="index"
						ref="content"
						class="c-front-page-hero-mobile__content"
					>
						<div
							class="
								c-front-page-hero-mobile__headline-wrapper
								py-sm
							"
						>
							<BaseH1
								class="text-primary-button"
								v-text="item.title"
							/>
						</div>

						<!-- Media -->
						<div
							v-if="item.imageVideo"
							:class="[
								'c-front-page-hero-mobile__media',
								'relative bg-primary-medium',
							]"
						>
							<!-- Image -->
							<UmbracoImageExt
								v-if="
									item.imageVideo.fileType === 'Image' &&
									item.imageVideo.cropUrl
								"
								:alt="item.imageVideo.altText"
								:widths="[
									320,
									480,
									720,
									834,
									1024,
									720 * 2,
									834 * 2,
									1024 * 2,
								]"
								:source-url="
									item.imageVideo.cropUrl ||
									item.imageVideo.url
								"
								:source-width="item.imageVideo.width"
								:source-height="item.imageVideo.height"
								:aspect-ratio="375 / 281"
								:class="[
									'c-front-page-hero-mobile__image bg-primary-medium relative overflow-hidden',
								]"
								image-class-names="object-cover"
							/>

							<!-- Video -->
							<div
								v-else-if="
									item.imageVideo.fileType === 'Video' &&
									item.imageVideo.cropUrl
								"
								class="
									c-front-page-hero-mobile__video
									relative
									h-full
								"
							>
								<video
									id="hero-video"
									ref="heroVideo"
									autoplay
									muted
									playsInline
									loop
									preload="none"
									crossOrigin="anonymous"
									class="
										absolute
										left-0
										top-0
										w-full
										h-full
										object-cover
									"
									aria-label="stemningsvideo"
									@canplay="setCorrectPausePlay"
								>
									<source
										:src="item.imageVideo.cropUrl"
										type="video/mp4"
									/>
									Your browser does not support the video tag.
								</video>
							</div>
						</div>

						<!-- Video controls if video -->
						<div
							v-if="
								item.imageVideo &&
								item.imageVideo.fileType === 'Video'
							"
							class="absolute right-0 bottom-0"
						>
							<BaseButton
								v-if="videoIsPlaying"
								class="
									c-front-page-hero-mobile__toggle
									absolute
									bottom-16
									right-0
									z-10
								"
								aria-label="Sæt videoen på pause"
								aria-controls="hero-video"
								@click="toggleVideo()"
							>
								<template #icon>
									<SvgPauseIcon class="w-24 h-24" />
								</template>
							</BaseButton>
							<BaseButton
								v-else
								class="
									c-front-page-hero-mobile__toggle
									absolute
									bottom-16
									right-0
									z-10
								"
								aria-label="Afspil videoen"
								aria-controls="hero-video"
								@click="toggleVideo()"
							>
								<template #icon>
									<div
										class="
											w-24
											h-24
											flex
											items-center
											justify-center
										"
									>
										<SvgPlayIcon class="w-16 ml-2" />
									</div>
								</template>
							</BaseButton>
						</div>
					</div>
				</template>
			</TransitionGroup>

			<div class="c-front-page-hero-mobile__secondary-area">
				<nav
					v-if="content.length > 1"
					aria-label="intro-område-navigering"
					class="flex items-center gap-x-8 mb-sm z-10"
				>
					<input
						v-for="(item, index) in content"
						:key="index"
						type="radio"
						name="intro-nav"
						:aria-label="`Gå til intro-område ${index + 1}`"
						:aria-current="introIndex === index ? 'true' : null"
						class="
							appearance-none
							border border-primary-button
							w-12
							h-12
							rounded-full
							transition-colors
							duration-300
						"
						:class="{
							'bg-primary cursor-pointer': introIndex !== index,
							'bg-primary-button': introIndex === index,
						}"
						@change="$event.target.checked && (introIndex = index)"
					/>
				</nav>

				<TransitionGroup
					tag="div"
					name="t-front-page-hero-mobile__content-transition"
					class="
						c-front-page-hero-mobile__content-transition
						relative
						flex flex-col
						justify-center
						items-start
					"
					:class="{
						'c-front-page-hero-mobile__content-transition--back':
							introIndex < previousIntroIndex,
					}"
					@after-enter="contentHeight2 = null"
				>
					<template v-for="(item, index) in content">
						<div
							v-show="
								introIndex === index &&
								(item.teaser || (item.link && item.link[0]))
							"
							:key="index"
							ref="content2"
							class="
								c-front-page-hero-mobile__content
								c-front-page-hero-mobile__content--2
							"
						>
							<p
								v-if="item.teaser"
								class="
									whitespace-pre-wrap
									mt-xs
									transform
									-translate-y-4
								"
								v-text="item.teaser"
							></p>

							<BaseButton
								v-if="item.link && item.link[0]"
								tag="NuxtLinkExt"
								class="inline-flex mt-xs"
								:to="item.link[0].url"
								:target="item.link[0].target"
								v-text="item.link[0].name"
							/>
						</div>
					</template>
				</TransitionGroup>
			</div>
		</div>
	</header>
</template>

<script>
import UmbracoImageExt from '~/components/shared/UmbracoImageExt.vue';
import SvgPlayIcon from '~/assets/svgs/play-icon.svg?inline';
import SvgPauseIcon from '~/assets/svgs/pause-icon.svg?inline';

export default {
	name: 'FrontPageHero',
	components: {
		UmbracoImageExt,
		SvgPlayIcon,
		SvgPauseIcon,
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		content: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			introIndex: this.value,
			previousIntroIndex: this.value,
			contentHeight: null,
			contentHeight2: null,
			videoIsPlaying: true,
		};
	},
	watch: {
		value(newVal) {
			this.introIndex = newVal;
		},
		introIndex(newVal, oldVal) {
			this.$refs.heroVideo?.pause?.();
			this.previousIntroIndex = oldVal;
			this.setCorrectPausePlay();
			this.$emit('input', newVal);
		},
	},
	methods: {
		toggleVideo() {
			this.videoIsPlaying = !this.videoIsPlaying;
			if (this.videoIsPlaying) {
				this.$refs.heroVideo?.play?.();
			} else {
				this.$refs.heroVideo?.pause?.();
			}
		},
		setCorrectPausePlay() {
			if (!this.videoIsPlaying) {
				this.$refs.heroVideo?.pause?.();
			} else {
				this.$refs.heroVideo?.play?.();
			}
		},

		updateContentHeight() {
			const height =
				this.$refs.content?.[this.previousIntroIndex]?.offsetHeight;
			this.contentHeight = null;
			this.$nextTick(() => {
				const height2 =
					this.$refs.content?.[this.introIndex]?.offsetHeight;
				if (height !== height2) {
					this.contentHeight = height || 0;

					this.$nextTick(() => {
						this.contentHeight = height2 || 0;
					});
				}
			});

			const lowerHeight =
				this.$refs.content2?.[this.previousIntroIndex]?.offsetHeight;
			this.contentHeight2 = null;
			this.$nextTick(() => {
				const height2 =
					this.$refs.content2?.[this.introIndex]?.offsetHeight;
				if (lowerHeight !== height2) {
					this.contentHeight2 = lowerHeight || 0;

					this.$nextTick(() => {
						this.contentHeight2 = height2 || 0;
					});
				}
			});
		},
	},
};
</script>

<style lang="postcss">
.c-front-page-hero-mobile {
	@apply relative flex flex-col px-layout-margin bg-white;
	z-index: 21;
	overflow: hidden;
}
.c-front-page-hero-mobile__content {
	@apply w-full flex flex-col justify-center items-start;
	transition: height 200ms ease-in-out;
	height: var(--content-height, auto);
}
.c-front-page-hero-mobile__content--2 {
	height: var(--content-height-2, auto);
}
.c-front-page-hero-mobile__media {
	width: 100%;
	height: 281px;
}
.c-front-page-hero-mobile__image,
.c-front-page-hero-mobile__video {
	margin-inline: calc(
		var(--theme-layout-margin, var(--theme-layout-margin--sm)) * -1
	);
	width: calc(
		100% + var(--theme-layout-margin, var(--theme-layout-margin--sm)) * 2
	);
	height: 100%;

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 0;
		height: min(50%, 202px);
		background: linear-gradient(
			0deg,
			var(--color-primary) 0%,
			var(--color-primaryTransparent) 100%
		);
	}
}

.c-front-page-hero-mobile__secondary-area {
	@apply relative pt-sm;

	&:before {
		@apply bg-primary-medium;
		content: '';
		display: block;
		position: absolute;
		left: calc(
			var(--theme-layout-margin, var(--theme-layout-margin--sm)) * -1
		);
		width: calc(
			100% + var(--theme-layout-margin, var(--theme-layout-margin--sm)) *
				2
		);
		top: 0;
		height: 100%;
		z-index: -1;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: calc(
			var(--theme-layout-margin, var(--theme-layout-margin--sm)) * -1
		);
		width: calc(
			100% + var(--theme-layout-margin, var(--theme-layout-margin--sm)) *
				2
		);
		bottom: 100%;
		height: min(50%, 202px);
		background: linear-gradient(
			0deg,
			var(--color-primary) 0%,
			var(--color-primaryTransparent) 100%
		);
		z-index: -1;
	}
}

/* Vue transition */
.t-front-page-hero-mobile__content-transition-enter-active,
.t-front-page-hero-mobile__content-transition-leave-active {
	@apply transition-all duration-300;
}
.t-front-page-hero-mobile__content-transition-enter-active {
	@apply delay-300;
}
.t-front-page-hero-mobile__content-transition-leave-active {
	@apply absolute w-full;
}
.t-front-page-hero-mobile__content-transition-enter,
.t-front-page-hero-mobile__content-transition-leave-to {
	@apply opacity-0;
}
</style>
