<template>
	<article class="d-front-page-custom relative bg-sand">
		<FrontPageHeroMobile
			v-if="hero"
			v-model="heroIndex"
			class=">=1024:hidden"
			:content="hero.map((item) => item.content)"
		/>
		<Component
			:is="heroComponent"
			v-if="hero"
			v-model="heroIndex"
			class="<1024:hidden"
			:content="hero.map((item) => item.content)"
		/>

		<div class="mx-layout-margin relative">
			<BlockList :block-list="contentElements" class="w-full pb-xl" />
		</div>

		<FeedbackForm v-if="activateFeedback" class="mb-lg" />
	</article>
</template>

<script>
import FrontPageHero from '~/components/subsite/FrontPageHero.vue';
import FrontPageHeroWide from '~/components/subsite/FrontPageHeroWide.vue';
import FrontPageHeroMobile from '~/components/subsite/FrontPageHeroMobile.vue';
import BlockList from '~/components/subsite/BlockList';
import FeedbackForm from '~/components/shared/FeedbackForm';

const heros = {
	Smal: FrontPageHero,
	Bred: FrontPageHeroWide,
};

export default {
	name: 'SubsiteFrontPageCustom',

	components: {
		FrontPageHeroMobile,
		BlockList,
		FeedbackForm,
	},

	inheritAttrs: false,

	props: {
		hero: {
			type: Array,
			default: () => [],
		},
		layout: {
			type: String,
			default: 'Smal',
		},

		contentElements: {
			type: Array,
			default: () => [],
		},
		activateFeedback: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			heroIndex: 0,
		};
	},

	computed: {
		heroComponent() {
			return heros[this.layout] || heros.Smal;
		},
	},
};
</script>
